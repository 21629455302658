<!-- 基本信息 -->
<template>
	<div class="main-container">
		<div class="side-header">
			<div class="name">
				个人资料
			</div>
			<div class="operate" @click="changeEdit">
				{{editVisible ? '保存' : '编辑'}}
			</div>
		</div>
		<div class="main-content" :class="editVisible?'main-edit':'main-normal'">
			<el-form ref="form" class="info-form" :model="form" :rules="rules" label-position="left" label-width="100px">
				<el-form-item label="头像：" class="head-item">
					<el-upload 
					class="avatar-uploader" 
					:action="config.qnuploadurl" 
					:show-file-list="false"
					:data="{token: qntoken}" 
					:on-success="uploadSuccess" v-if="editVisible">
						<img v-if="form.logo" :src="form.logo" class="avatar">
						<i class="el-icon-camera avatar-uploader-icon"></i>
					</el-upload>
					<div v-else>
						<img :src="form.logo" class="avatar" v-if="form.logo" alt="">
						<img src="@/assets/images/ip_default_head.jpg" class="avatar" v-else alt="">
					</div>
				</el-form-item>
				<el-form-item label="学校名称：" prop="companyName">
					<el-input v-model="form.companyName" :disabled="!!form.companyName" maxlength="10" v-if="editVisible"></el-input>
					<div class="txt" v-else>{{form.companyName}}</div>
				</el-form-item>
				<el-form-item label="联系人：" prop="companyName">
					<el-input v-model="form.contact" maxlength="10" v-if="editVisible"></el-input>
					<div class="txt" v-else>{{form.contact}}</div>
				</el-form-item>
				<el-form-item label="联系方式：" prop="companyName">
					<el-input v-model="form.telephone" maxlength="11" v-if="editVisible"></el-input>
					<div class="txt" v-else>{{form.telephone}}</div>
				</el-form-item>
				<el-form-item label="学校简介：" prop="description">
					<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 5 }" v-model="form.description" v-if="editVisible"></el-input>
					<div class="txt" v-else>{{form.description}}</div>
				</el-form-item>
				<el-form-item label="所在地址：" prop="city">
					<el-cascader size="large" :options="options" v-model="form.city" @change="changeCity"
						v-if="editVisible">
					</el-cascader>
					<el-input v-model="form.localhost" v-if="editVisible" style="margin-left: 20px;"></el-input>
					<div class="txt" v-else>{{form.provinceName +'/'+ form.cityName +'/'+ form.districtName +'/'+ form.localhost}}</div>
				</el-form-item>
			</el-form>
		</div>
		<div class="btn-box" v-if="editVisible">
			<el-button @click="editVisible = false">取消</el-button>
			<el-button type="primary" v-preventReClick @click="onSubmit">保存</el-button>
		</div>
	</div>
</template>

<script>
	import config from "@/config";
	import { regionData, CodeToText } from "element-china-area-data";
	import moment from "moment";
	export default {
		filters: {
			stringData: function (date) {
				if(!!date){
					return moment(date*1000).format("YYYY-MM-DD");
				}else{
					return '未知';
				}	
			},
		},
		data() {
			return {
				editVisible: false, // 是否是编辑状态
				qntoken: '',
				config,
				form: {
					logo: require('@/assets/images/ip_default_head.jpg'),
					companyName: '',
					description: '',
					localhost:'',
					provinceId: '',
					provinceName:'',
					cityId: '',
					cityName:'',
					districtId: '',
					districtName:'',
					city: ['', '', ''],
					information:''
				},															
				pickerBeginDateBefore: {
					disabledDate(time) {
						let timeSpace = time.getTime() > Date.now();
						return timeSpace;
					}
				},
				options: regionData,
				rules: {
					nickname: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}],
					abstract: [{
						required: true,
						message: '请输入简述',
						trigger: 'blur'
					}],				
					city: [{
						required: true,
						message: '请输入所在城市',
						trigger: 'blur'
					}],
					address:[{
						required: true,
						message: '请输入详细地址',
						trigger: 'blur'
					}],
					information: [{
						required: true,
						message: '请输入代理信息',
						trigger: 'blur'
					}],
				},
			};
		},
		created() {
			this.getQnToken();
			this.getMyUserInfo();
		},
		methods: {
			getQnToken() {
				this.$store.dispatch("GetUploadToken").then((res) => {
					this.qntoken = res.data.qnToken;
				});
			},
			getMyUserInfo() {
				this.$http
					.get('/api/web/company/getCompanyInfo')
					.then(res => {
						this.form = res.data.companyInfo;
						const {provinceId,cityId,districtId} = res.data.companyInfo;
						this.form.city = [String(provinceId), String(cityId), String(districtId)];
						// let userInfo=JSON.parse(localStorage.getItem('userInfo'));
						// this.$store.commit('SET_USER_INFO',Object.assign(userInfo,{SchoolLogo:this.form.logo}))
					})
			},
			uploadSuccess(res) {
				this.form.logo = config.qnurl + res.key;
			},
			changeCity() {
				this.form.provinceId = this.form.city[0];
				this.form.cityId = this.form.city[1];
				this.form.districtId = this.form.city[2];
			},
			changeEdit() {
				if (this.editVisible) {
					this.onSubmit()
				} else {
					this.editVisible = !this.editVisible;
				}
			},
			onSubmit() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						this.$http.post(
								'/api/web/company/postEditCompanyInfo', this.form
							)
							.then((res) => {
								this.$toast(res.msg);
								let userInfo=JSON.parse(localStorage.getItem('userInfo'));
								this.$store.commit('SET_USER_INFO',Object.assign(userInfo,{								
									logo:this.form.logo,
									companyName:this.form.companyName
								}));
								this.editVisible = false;
								// console.log(Object.assign(userInfo,{logo:this.form.logo}))
							})
							.catch((err) => {
								this.$toast(err.msg)
							});
					} else {
						// this.$toast('请填写完整表单')
						return false;
					}
				});

			},
		}
	};
</script>

<style lang='less' scoped>
	.main-container {
		background: #fff;

		.side-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 68px;
			padding: 0 20px 0 40px;
			border-bottom: 1px solid #F5F5F5;

			.name {
				font-size: 16px;
				color: #333;
			}

			.operate {
				line-height: 68px;
				padding: 0 20px;
				color: #D71A18;
				cursor: pointer;
			}
		}

		.main-content {
			padding: 0 30px 40px;

			&.main-normal {
				/deep/.el-form-item__label {
					&::before {
						display: none;
					}
				}
			}

			/deep/.info-form {
				padding: 40px 10px 0;

				.head-item {
					height: 90px;

					.el-form-item__label {
						line-height: 90px;
					}					
					.el-form-item__error{
						line-height: 30px;
					}
					.avatar-uploader .el-upload {
						position: relative;
						width: 90px;
						height: 90px;
						border: 1px dashed #d9d9d9;
						border-radius: 6px;
						cursor: pointer;
						position: relative;
						overflow: hidden;
						border-radius: 50%;
						background: #00000040;
					}

					.avatar-uploader .el-upload:hover {
						border-color: #409EFF;
					}

					.avatar-uploader-icon {
						position: absolute;
						left: 0;
						top: 0;
						font-size: 28px;
						color: #fff;
						width: 90px;
						height: 90px;
						line-height: 90px;
						text-align: center;
					}

					.avatar {
						width: 90px;
						height: 90px;
						border-radius: 50%;
					}
				}

				.el-form-item {
					width: 550px;
					margin-bottom: 30px;

					// display: flex;
					.el-form-item__label {
						position: relative;

						&::before {
							position: absolute;
							top: 2px;
							left: -2px;
						}

						padding-left: 10px;
					}

					.el-form-item__content {
						display: flex;
					}

					.el-cascader,
					.el-input {
						width: 100%;
					}
				}
			}

			.txt {
				color: #333;
			}
		}

		.btn-box {
			border-top: 1px solid #f5f5f5;
			padding: 40px 0 50px;

			.el-button {
				min-width: 180px;

				&:first-child {
					margin-right: 30px;
				}
			}
		}
	}
</style>
